<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >
    <b-modal
        id="certificateProcessTypeSaveModal"
        body-class="position-static"
        centered
        :title="isCreated ? 'Thêm mới dữ liệu' : 'Cập nhật dữ liệu'"
        no-close-on-backdrop
        @show="onShow"
        @hidden="onHide"
    >
      <b-form>
        <b-form-group label-for="name">
          <template v-slot:label>
            Tên loại yêu cầu <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{errors}"
              name="Tên loại yêu cầu"
              rules="required"
          >
            <b-form-input
                id="name"
                v-model="targetItem.name"
                placeholder="Nhập tên loại yêu cầu"
                :state="getElementState"
            >
              <small class="text-danger"> {{ errors[0] }}</small>
            </b-form-input>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="description">
          <template v-slot:label>
            Mô tả loại yêu cầu <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{errors}"
              name="Mô tả loại yêu cầu"
              rules="required"
          >
            <b-form-input
                id="description"
                v-model="targetItem.description"
                placeholder="Nhập mô tả loại yêu cầu"
                :state="getElementState"
            >
              <small class="text-danger"> {{ errors[0] }}</small>
            </b-form-input>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="code">
          <template v-slot:label>
            Mã <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{errors}"
              name="Mã code"
              rules="required"
          >
            <b-form-input
                id="code"
                v-model="targetItem.code"
                placeholder="Nhập mã code"
                :state="getElementState"
            >
              <small class="text-danger"> {{ errors[0] }}</small>
            </b-form-input>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Trạng thái"
              rules="required"
          >
            <v-select
                v-model="targetItem.status"
                :options="statusOptions"
                :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('certificateProcessTypeSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" />Hủy
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>

import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CertificateProcessTypeSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        name: '',
        description: '',
        code: '',
        status: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'credential/statuses',
    }),
    isCreated() {
      return !this.item
    },
    statusOptions() {
      return this.statuses
    },
  },
  methods: {
    ...mapActions({
      createCredential: 'credential/createCredential',
      updateCredential: 'credential/updateCredential',
      createCertificateProcessTypes: 'certificateProcessType/createCertificateProcessTypes',
      updateCertificateProcessTypes: 'certificateProcessType/updateCertificateProcessType',
    }),
    async onShow() {
      if (this.item) {
        this.targetItem = { ...this.item }
      }
    },
    onHide() {
      this.targetItem = {
        name: '',
        description: '',
        type: '',
        status: 1,
      }
    },
    getElementState(errors) {
      return !(errors.length > 0)
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createCertificateProcessTypes(this.targetItem)
            : await this.updateCertificateProcessTypes(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('certificateProcessTypeSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },

}
</script>

<style scoped lang="scss">
</style>
